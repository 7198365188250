import { forwardRef } from 'react'
import styles from './ApieMus.module.scss'
import { Section } from '../Nav/Nav'
import SectionWrapper from '../SectionWrapper/SectionWrapper'

interface Props {
  goTo: (elementKey: Section) => void
}

const ApieMus = forwardRef<HTMLDivElement, Props>(({ goTo }, ref) => (
  <SectionWrapper
    sectionClassName={styles.apieMus}
    id={Section.ApieMus}
    sectionRef={ref}
    nextSection={Section.Kontaktai}
    goTo={goTo}
  >
    <h2>Apie mus</h2>
    <div className={styles.information}>
      <p>
        Teikiame visas taikomosios geodezijos ir žemėtvarkos darbų paslaugas. Atliekame žemės sklypų, pastatų ir
        inžinerinių statinių kadastrinius matavimus, topografines nuotraukas, įvairių statinių nužymėjimo darbus bei
        konsultacijas visų darbų atlikimo klausimais.
      </p>
      <p>Vykdome darbus visoje Lietuvoje.</p>
    </div>
  </SectionWrapper>
))

export default ApieMus
