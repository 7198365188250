import { paslauguTitles, Paslauga } from '../components/Service/Service'

interface EmailValues {
  services: string[]
  apskritis: string
  vardas: string
  kadastras: string
  pastas: string
  telefonas: string
  pastaba: string
}

const uriPrefix: string = process.env.NODE_ENV === 'development' ? 'http://localhost:5500' : ''

const sendInformationRequestEmail = (
  { services, apskritis, vardas, kadastras, telefonas, pastas, pastaba }: EmailValues,
  callback?: (response: Response) => void,
  onError?: (error: Response) => void,
) => {
  fetch(`${uriPrefix}/api/v1`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      services: services.map((service) => paslauguTitles[service as Paslauga]),
      apskritis,
      vardas,
      kadastras,
      telefonas,
      pastas,
      pastaba,
    }),
  })
    .then(callback)
    .catch(onError)
}

export default sendInformationRequestEmail
