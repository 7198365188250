import React from 'react'
import styles from './Kontaktai.module.scss'
import { Direction } from '../LinkArrow/LinkArrow'
import { Section } from '../Nav/Nav'
import KontaktuForm from '../KontaktuForm/KontaktuForm'
import SectionWrapper from '../SectionWrapper/SectionWrapper'

interface Props {
  goTo: (elementKey: Section) => void
}

const Kontaktai = React.forwardRef<HTMLDivElement, Props>(({ goTo }, ref) => (
  <SectionWrapper
    sectionClassName={styles.kontaktai}
    id={Section.Kontaktai}
    sectionRef={ref}
    nextSection={Section.Pagrindinis}
    arrowDirection={Direction.Up}
    goTo={goTo}
  >
    <h2>Kontaktai</h2>
    <KontaktuForm />
  </SectionWrapper>
))

export default Kontaktai
