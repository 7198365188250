import React from 'react'
import styles from './Pagrindinis.module.scss'
import Galerija from '../Gallery/Galerija'
import LinkArrow from '../LinkArrow/LinkArrow'
import { Section } from '../Nav/Nav'

interface Props {
  goTo: (elementKey: Section) => void
}

const Pagrindinis = React.forwardRef<HTMLDivElement, Props>(({ goTo }, ref) => (
  <div className={styles.pagrindinis} id={Section.Pagrindinis} ref={ref}>
    <div className={styles.textWrapper}>
      <h1 className={styles.title}>Visi taikomosios geodezijos, žemėtvarkos ir projektavimo darbai</h1>
    </div>
    <div onClick={() => goTo(Section.Paslaugos)} className={styles.button}>
      Daugiau
    </div>
    <Galerija />
    <LinkArrow section={Section.Paslaugos} goTo={goTo} />
  </div>
))

export default Pagrindinis
