import { FC } from 'react'
import clsx from 'clsx'
import styles from './Service.module.scss'

interface Props {
  Icon?: any
  title: Paslauga
  description?: string
}

export enum Paslauga {
  Geodeziniai = 'geodeziniai',
  Pastatu = 'pastatu',
  Nuotraukos = 'nuotraukos',
  Formavimo = 'formavimo',
  Kontroliniu = 'kontroliniu',
  Nuzymejimas = 'nuzymejimas',
  Nustatymas = 'nustatymas',
  Atstatymas = 'atstatymas',
  Taikomosios = 'taikomosios',
}

export const paslauguTitles = {
  [Paslauga.Geodeziniai]: 'Žemės sklypų kadastriniai (geodeziniai) matavimai',
  [Paslauga.Pastatu]: 'Pastatų (patalpų), inžinerinių statinių kadastriniai matavimai',
  [Paslauga.Nuotraukos]: 'Topografinės nuotraukos',
  [Paslauga.Formavimo]: 'Žemės sklypų formavimo ir pertvarkymo projektai',
  [Paslauga.Kontroliniu]: 'Pastatų, inžinerinių statinių kontrolinių (išpildomųjų geodezinių) nuotraukų atlikimas',
  [Paslauga.Nuzymejimas]: 'Pastatų, inžinerinių statinių nužymėjimas',
  [Paslauga.Nustatymas]: 'Naudojimosi žemės sklypu tvarkos nustatymas',
  [Paslauga.Atstatymas]: 'Žemės sklypų riboženklių atstatymas (nužymėjimas vietoje)',
  [Paslauga.Taikomosios]: 'Kiti taikomosios geodezijos ir žemėtvarkos darbai',
}

const Service: FC<Props> = ({ Icon, title, description }) => (
  <div className={styles.service} id="service">
    <div className={clsx(styles.icon, !Icon && styles.placeholder)}>{Icon && <Icon />}</div>
    <h3 className={styles.title}>{paslauguTitles[title]}</h3>
    {description && <p className={styles.description}>{description}</p>}
  </div>
)

export default Service
