import { FC, useState, Dispatch, SetStateAction, ChangeEvent, FormEvent } from 'react'
import styles from './KontaktuForm.module.scss'
import {
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  InputLabel,
  Select,
  TextField,
  Grid,
  FormHelperText,
  Button,
  OutlinedInput,
  LinearProgress,
} from '@material-ui/core'
import { Validator, contains, isNotEmpty, isNotEmptyArray, isValidEmail, isValidPhone } from '../../utils/formUtils'
import sendInformationRequestEmail from '../../utils/emailUtils'
import { paslauguTitles, Paslauga } from '../Service/Service'
import { EMAIL, TELEPHONE } from '../../config'

interface Field<T extends string | string[]> {
  value: T
  isBlurred: boolean
  validators: Validator<T>[]
  error: boolean | string
}
type FieldSetter<T extends string | string[]> = Dispatch<SetStateAction<Field<T>>>

const KontaktuForm: FC = () => {
  const emptyField: Field<string> = { value: '', isBlurred: false, validators: [], error: false }
  const [services, setServices] = useState<Field<string[]>>({ ...emptyField, value: [], validators: [isNotEmptyArray] })
  const [apskritis, setApskritis] = useState<Field<string>>({ ...emptyField, validators: [isNotEmpty] })
  const [vardas, setVardas] = useState<Field<string>>({ ...emptyField, validators: [isNotEmpty] })
  const [kadastras, setKadastras] = useState<Field<string>>({ ...emptyField, validators: [] })
  const [pastas, setPastas] = useState<Field<string>>({ ...emptyField, validators: [isNotEmpty, isValidEmail] })
  const [telefonas, setTelefonas] = useState<Field<string>>({ ...emptyField, validators: [isValidPhone] })
  const [pastaba, setPastaba] = useState<Field<string>>(emptyField)
  const [error, setError] = useState<string>('')
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false)
  const [isSent, setIsSent] = useState<boolean>(false)

  const updateBlur = (field: Field<string>, fieldSetter: FieldSetter<string>) => () => {
    const error = getFieldError(field)
    fieldSetter((previous) => ({ ...previous, isBlurred: true, error }))
  }

  const updateInput = (field: Field<string>, fieldSetter: FieldSetter<string>) => ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    const { isBlurred } = field
    if (isBlurred) {
      const error = getFieldError({ ...field, value })
      fieldSetter((previous) => ({ ...previous, value, error }))
    } else {
      fieldSetter((previous) => ({ ...previous, value }))
    }
    setIsSubmitted(false)
  }

  const updateSelectInput = (field: Field<string>, fieldSetter: FieldSetter<string>) => ({
    target: { value: newValue },
  }: ChangeEvent<{
    name?: string | undefined
    value: unknown
  }>) => {
    const { isBlurred } = field
    const value = newValue as string
    if (isBlurred) {
      const error = getFieldError({ ...field, value })
      fieldSetter((previous) => ({ ...previous, value, error }))
    } else {
      fieldSetter((previous) => ({ ...previous, value }))
    }
    setIsSubmitted(false)
  }

  const updateCheckbox = (name: string) => ({ target: { checked } }: ChangeEvent<HTMLInputElement>) => {
    const selectedValues: string[] = services.value
    const value = checked ? [...selectedValues, name] : selectedValues.filter((service) => service !== name)
    const error = getFieldError({ ...services, value })
    setServices((previous) => ({ ...previous, value, error }))
  }

  const getFieldError = <T extends string | string[]>({ value, validators }: Field<T>): boolean | string => {
    const errors = validators.map((validator) => validator(value)).filter((errorMessages) => !!errorMessages)
    return !!errors && (errors.length > 0 ? errors[0] : false)
  }

  const validateForm = () => {
    const validateField = <T extends string | string[]>(fieldSetter: FieldSetter<T>): void =>
      fieldSetter((previous) => ({ ...previous, error: getFieldError(previous) }))
    ;[setApskritis, setVardas, setKadastras, setPastas, setTelefonas].forEach(validateField)
    validateField(setServices)
  }

  const isFormValid: boolean =
    [services, apskritis, vardas, kadastras, pastas, telefonas].map(({ error }) => !!error).filter((error) => error)
      .length === 0

  const submitForm = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    validateForm()
    setError(isFormValid ? '' : 'Ištaisykite savo informaciją')
    setIsSubmitted(true)
    setIsSent(false)

    const values = {
      services: services.value,
      apskritis: apskritis.value,
      vardas: vardas.value,
      kadastras: kadastras.value,
      pastas: pastas.value,
      telefonas: telefonas.value,
      pastaba: pastaba.value,
    }

    if (isFormValid) {
      sendInformationRequestEmail(values, ({ ok }: Response) => {
        ok ? setIsSent(true) : setError('Klaidos siunčiant informaciją')
      }, (error: any) => void setError('Klaidos siunčiant informaciją'))
    }
  }

  const apskritys = [
    'Alytaus apskritis',
    'Kauno apskritis',
    'Klaipėdos apskritis',
    'Marijampolės apskritis',
    'Panevėžio apskritis',
    'Šiaulių apskritis',
    'Tauragės apskritis',
    'Telšių apskritis',
    'Utenos apskritis',
    'Vilniaus apskritis',
  ]

  return (
    <div className={styles.kontaktuForm}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} className={styles.kontaktuSection}>
          <div className={styles.info}>
            <h3 className={styles.subtitle}>Susisiekite su mumis</h3>
            <p>
              Iškilus klausimams, prašome užpildyti žemiau esančią formą – mielai atsakysime ir pateiksime visą Jus
              dominančią informaciją.
            </p>
            <p className={styles.requiredInfoText}>
              Visi laukeliai, pažymėti <strong className={styles.asterix}>*</strong>, yra privalomi.
            </p>
          </div>
          <form onSubmit={submitForm}>
            <Grid container direction="column" spacing={3}>
              <Grid item>
                <FormControl error={!!services.error}>
                  <FormLabel>Kurios(-ų) paslaugos(-ų) informaciją norėtumėte sužinoti? *</FormLabel>
                  <FormGroup>
                    {Object.values(Paslauga).map((paslauga) => (
                      <FormControlLabel
                        key={paslauga}
                        control={
                          <Checkbox
                            checked={contains(services.value, paslauga)}
                            onChange={updateCheckbox(paslauga)}
                            value={paslauga}
                          />
                        }
                        label={paslauguTitles[paslauga as Paslauga]}
                      />
                    ))}
                  </FormGroup>
                  {!!services.error && <FormHelperText error>{services.error}</FormHelperText>}
                </FormControl>
              </Grid>
              <Grid item>
                <TextField
                  error={!!vardas.error}
                  fullWidth
                  id="vardas"
                  variant="outlined"
                  value={vardas.value}
                  onChange={updateInput(vardas, setVardas)}
                  onBlur={updateBlur(vardas, setVardas)}
                  label="Vardas *"
                />
                {!!vardas.error && <FormHelperText error>{vardas.error}</FormHelperText>}
              </Grid>
              <Grid item>
                <TextField
                  error={!!pastas.error}
                  fullWidth
                  id="pastas"
                  variant="outlined"
                  value={pastas.value}
                  onChange={updateInput(pastas, setPastas)}
                  onBlur={updateBlur(pastas, setPastas)}
                  label="El. pašto adresas *"
                />
                {!!pastas.error && <FormHelperText error>{pastas.error}</FormHelperText>}
              </Grid>
              <Grid item>
                <TextField
                  error={!!telefonas.error}
                  fullWidth
                  id="telefonas"
                  variant="outlined"
                  value={telefonas.value}
                  onChange={updateInput(telefonas, setTelefonas)}
                  onBlur={updateBlur(telefonas, setTelefonas)}
                  label="Telefono numeris"
                />
                {!!telefonas.error && <FormHelperText error>{telefonas.error}</FormHelperText>}
              </Grid>
              <Grid item>
                <FormControl error={!!apskritis.error} fullWidth className={styles.formControl}>
                  <InputLabel variant="outlined" htmlFor="apskritis">
                    Apskritis *
                  </InputLabel>
                  <Select
                    native
                    value={apskritis.value}
                    onChange={updateSelectInput(apskritis, setApskritis)}
                    input={<OutlinedInput name="apskritis" id="apskritis" labelWidth={127} />}
                  >
                    <option value="" />
                    {apskritys.map((apskritisOption) => (
                      <option key={apskritisOption} value={apskritisOption}>
                        {apskritisOption}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                <FormHelperText>Kur yra Jūsų žemės valda?</FormHelperText>
                {!!apskritis.error && <FormHelperText error>{apskritis.error}</FormHelperText>}
              </Grid>
              <Grid item>
                <TextField
                  error={!!kadastras.error}
                  fullWidth
                  id="kadastras"
                  variant="outlined"
                  value={kadastras.value}
                  onChange={updateInput(kadastras, setKadastras)}
                  onBlur={updateBlur(kadastras, setKadastras)}
                  label="Žemės sklypo(-ų) kadastro"
                />
                <FormHelperText>Arba unikalus(-ūs) numeris(-iai)</FormHelperText>
                {!!kadastras.error && <FormHelperText error>{kadastras.error}</FormHelperText>}
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  multiline
                  rows={5}
                  variant="outlined"
                  id="pastaba"
                  value={pastaba.value}
                  onChange={updateInput(pastaba, setPastaba)}
                  label="Pastaba"
                />
              </Grid>
              <Grid item>
                <Button type="submit" variant="contained" size="large" disabled={isSubmitted && !error && !isSent}>
                  Siųsti informaciją
                </Button>
                {isSubmitted && error && <p className={styles.wholeFormError}>{error}</p>}
                {isSubmitted &&
                  !error &&
                  (isSent ? (
                    <div className={styles.success}>Jūsų informacija buvo išsiųsta</div>
                  ) : (
                    <div>
                      <p>Informacijos siuntimas...</p>
                      <LinearProgress color="secondary" />
                    </div>
                  ))}
              </Grid>
            </Grid>
          </form>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className={styles.kontaktuInformation}>
            <h3>El. pašto adresas</h3>
            <p>
              <span className={styles.bold}>
                <a href={`mailto:${EMAIL}`}>{EMAIL}</a>
              </span>
            </p>
            <h3>Telefonas</h3>
            <p>
              <span className={styles.bold}>{TELEPHONE}</span>
            </p>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default KontaktuForm
