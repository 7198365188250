import { FC } from 'react'
import styles from './LinkArrow.module.scss'
import { Section } from '../Nav/Nav'

interface Props {
  section: Section
  direction?: Direction
  goTo: (elementKey: Section) => void
}

export enum Direction {
  Up = '↑',
  Down = '↓',
}

const LinkArrow: FC<Props> = ({ section, goTo, direction = Direction.Down }) => (
  <div onClick={() => goTo(section)} className={styles.linkArrow}>
    {direction}
  </div>
)

export default LinkArrow
