import { FC } from 'react'
import styles from './Galerija.module.scss'

const pictureCount: number = Number(styles.pictureCount)

const Galerija: FC = () => (
  <div className={styles.galerija}>
    <div className={styles.smoke} />
    {Array.from({ length: pictureCount }, (_, i) => (
      <div key={i} className={styles[`picture${pictureCount - i}`]} />
    ))}
  </div>
)

export default Galerija
