import React, { FC } from 'react'
import styles from './App.module.scss'
import theme from '../../styles/Theme'
import { MuiThemeProvider } from '@material-ui/core/styles'
import Nav, { Section } from '../Nav/Nav'
import Pagrindinis from '../Pagrindinis/Pagrindinis'
import ApieMus from '../ApieMus/ApieMus'
import Paslaugos from '../Paslaugos/Paslaugos'
import Kontaktai from '../Kontaktai/Kontaktai'
import Footer from '../Footer/Footer'
import ScrollNavigation from 'react-single-page-navigation'

const sections = {
  [Section.Pagrindinis]: {},
  [Section.Paslaugos]: {},
  [Section.ApieMus]: {},
  [Section.Kontaktai]: {},
}

const updateUrl = (route: Section): void => void window.history.pushState(undefined, '', `#${route}`)

const navigateTo = (callback?: (route: Section) => void) => (route: Section): void => {
  updateUrl(route)
  if (callback) {
    callback(route)
  }
}

const App: FC = () => (
  <MuiThemeProvider theme={theme}>
    <div className={styles.app}>
      <ScrollNavigation elements={sections}>
        {({ refs, activeElement, goTo }) => (
          <>
            <Pagrindinis ref={refs[Section.Pagrindinis]} goTo={navigateTo(goTo)} />
            <Paslaugos ref={refs[Section.Paslaugos]} goTo={navigateTo(goTo)} />
            <ApieMus ref={refs[Section.ApieMus]} goTo={navigateTo(goTo)} />
            <Kontaktai ref={refs[Section.Kontaktai]} goTo={navigateTo(goTo)} />
            <Footer />
            <Nav activeElement={activeElement} goTo={navigateTo(goTo)} updateUrl={updateUrl} />
          </>
        )}
      </ScrollNavigation>
    </div>
  </MuiThemeProvider>
)

export default App
