export const contains = (container: string | string[], containee: string) => !!~container.indexOf(containee)

export type Validator<T extends string | string[]> = (value?: T) => boolean | string

export const isNotEmpty = (value?: string) => (!!value ? false : 'Laukelis yra privalomas')

export const isNotEmptyArray = (value?: string[]) => (value && value.length > 0 ? false : 'Laukelis yra privalomas')

export const isValidEmail = (value?: string) =>
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    (value || '').toLowerCase(),
  )
    ? false
    : 'Įveskite galiojantį el. pašto adresą'

export const isValidPhone = (value?: string) =>
  /^[0-9 \-+().]*$/.test(value || '') ? false : 'Įveskite galiojantį telefono numerį'
