import { createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#7F7E7A',
    },
    secondary: {
      main: '#076300',
    },
  },
})

export default theme
