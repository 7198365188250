import { FC } from 'react'
import { EMAIL, TELEPHONE } from '../../config'
import styles from './Footer.module.scss'
import { ReactComponent as Triangles } from './footer_triangles.svg'
import { ReactComponent as KPF } from './KPF_Logo.svg'

const Footer: FC = () => (
  <div className={styles.footerWrapper}>
    <div className={styles.footer}>
      <div className={styles.text}>
        <p className={styles.bold}>MB „Tagita“</p>
        <p>
          El. p.{' '}
          <span className={styles.bold}>
            <a href={`mailto:${EMAIL}`}>{EMAIL}</a>
          </span>
        </p>
        <p>Ventos g. 14, Molainių k., Panevėžio r., 37175</p>
        <p>
          Tel. <span className={styles.bold}>{TELEPHONE}</span>
        </p>
        <p>
          Kodas <span className={styles.bold}>304961736</span>
        </p>
        <p>
          PVM mokėtojo kodas <span className={styles.bold}>LT100012312011</span>
        </p>
        <p>Įregistruota Lietuvos Respublikos Juridinių asmenų registre</p>
        <p>
          Atsiskaitomoji sąskaita <span className={styles.bold}>Nr. LT97 7300 01015741 2917</span>
        </p>
        <p>„Swedbank“, AB</p>
        <KPF className={styles.kpf} />
      </div>
      <Triangles className={styles.triangles} />
    </div>
  </div>
)

export default Footer
