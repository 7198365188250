import { FC, memo, useState, useEffect } from 'react'
import styles from './Nav.module.scss'
import clsx from 'clsx'
import { TELEPHONE } from '../../config'

export enum Section {
  Pagrindinis = 'pagrindinis',
  Paslaugos = 'paslaugos',
  ApieMus = 'apieMus',
  Kontaktai = 'kontaktai',
}

const RawSection = {
  [Section.Pagrindinis]: 'Pagrindinis',
  [Section.Paslaugos]: 'Paslaugos',
  [Section.ApieMus]: 'Apie mus',
  [Section.Kontaktai]: 'Kontaktai',
}

interface Props {
  activeElement: Section | undefined
  goTo(elementKey: Section): void
  updateUrl(route: Section): void
}

const Nav: FC<Props> = memo(({ activeElement, goTo, updateUrl }) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)

  useEffect(() => {
    if (activeElement) {
      updateUrl(activeElement)
    }
  }, [activeElement, updateUrl])

  return (
    <header className={clsx(styles.header, { [styles.unblur]: isMenuOpen })}>
      <div onClick={() => void goTo(Section.Pagrindinis)} className={styles.logoLink}>
        <div className={styles.logo} />
      </div>
      <div className={clsx(styles.menu, { [styles.show]: isMenuOpen })}>
        <button className={styles.toggle} onClick={() => void setIsMenuOpen((isCurrentlyOpen) => !isCurrentlyOpen)}>
          <div className={styles.lineOne} />
          <div className={styles.lineTwo} />
        </button>
        {Object.values(Section).map((section) => (
          <div
            key={section}
            onClick={() => {
              goTo(section)
              setIsMenuOpen(false)
            }}
            className={clsx(styles.link, activeElement === section && styles.active)}
          >
            <span className={styles.linkText}>{RawSection[section]}</span>
          </div>
        ))}
        <div className={styles.number}>{TELEPHONE}</div>
      </div>
    </header>
  )
})

export default Nav
