import React, { Ref } from 'react'
import styles from './SectionWrapper.module.scss'
import clsx from 'clsx'
import LinkArrow, { Direction } from '../LinkArrow/LinkArrow'
import { Section } from '../Nav/Nav'

interface Props {
  sectionRef: Ref<HTMLDivElement>
  id: string
  sectionClassName: string
  nextSection: Section
  children: JSX.Element | JSX.Element[]
  arrowDirection?: Direction
  goTo: (elementKey: Section) => void
}

const SectionWrapper = ({ sectionRef, id, sectionClassName, nextSection, arrowDirection, goTo, children }: Props) => (
  <div className={clsx(styles.sectionWrapper, sectionClassName)} id={id} ref={sectionRef}>
    <div className={styles.content}>{children}</div>
    <LinkArrow section={nextSection} direction={arrowDirection} goTo={goTo} />
  </div>
)

export default SectionWrapper
