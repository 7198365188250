import React from 'react'
import styles from './Paslaugos.module.scss'
import Service, { Paslauga } from '../Service/Service'
import { ReactComponent as Icon1 } from './icon_1.svg'
import { ReactComponent as Icon2 } from './icon_2.svg'
import { ReactComponent as Icon3 } from './icon_3.svg'
import { ReactComponent as Icon4 } from './icon_4.svg'
import { ReactComponent as Icon5 } from './icon_5.svg'
import { ReactComponent as Icon6 } from './icon_6.svg'
import { ReactComponent as Icon7 } from './icon_7.svg'
import { ReactComponent as Icon8 } from './icon_8.svg'
import { ReactComponent as Icon9 } from './icon_9.svg'
import { Section } from '../Nav/Nav'
import SectionWrapper from '../SectionWrapper/SectionWrapper'

interface Props {
  goTo: (elementKey: Section) => void
}

const Paslaugos = React.forwardRef<HTMLDivElement, Props>(({ goTo }, ref) => (
  <SectionWrapper
    sectionClassName={styles.paslaugos}
    id={Section.Paslaugos}
    sectionRef={ref}
    nextSection={Section.ApieMus}
    goTo={goTo}
  >
    <h2>Paslaugos</h2>
    <div className={styles.services}>
      <Service title={Paslauga.Geodeziniai} Icon={Icon1} />
      <Service title={Paslauga.Pastatu} Icon={Icon2} />
      <Service title={Paslauga.Nuotraukos} Icon={Icon3} />
      <Service title={Paslauga.Formavimo} Icon={Icon4} />
      <Service title={Paslauga.Kontroliniu} Icon={Icon5} />
      <Service title={Paslauga.Nuzymejimas} Icon={Icon6} />
      <Service title={Paslauga.Nustatymas} Icon={Icon7} />
      <Service title={Paslauga.Atstatymas} Icon={Icon8} />
      <Service title={Paslauga.Taikomosios} Icon={Icon9} />
    </div>
  </SectionWrapper>
))

export default Paslaugos
